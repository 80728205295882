'use client';

import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { useRef } from 'react';

export const GrowthbookTestComponent = () => {
  return (
    <div style={{ height: '100vh', overflow: 'scroll' }}>
      <AboveTheFoldComponent />
      <div style={{ height: '100vh', width: 1 }} />
      <BelowTheFoldComponent />
    </div>
  );
};

export const BelowTheFoldComponent = ({ hidden }: { hidden?: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { variant, experiment } = useIsFeatureActive('my-feature-flag', {
    triggerEvent: true,
    viewEventRef: ref,
  });

  if (hidden) {
    return (
      <div ref={ref} data-experiment={experiment?.key} data-variant={variant} data-testid="below-the-fold-component" />
    );
  }

  return (
    <div ref={ref}>
      <h1>
        {variant} FOR {experiment?.key}
      </h1>
    </div>
  );
};

export const AboveTheFoldComponent = ({ hidden }: { hidden?: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { variant, experiment } = useIsFeatureActive('my-feature-flag', {
    triggerEvent: true,
    viewEventRef: ref,
  });

  if (hidden) {
    return (
      <div ref={ref} data-experiment={experiment?.key} data-variant={variant} data-testid="above-the-fold-component" />
    );
  }

  return (
    <div>
      <h1>
        {variant} FOR {experiment?.key}
      </h1>
    </div>
  );
};
