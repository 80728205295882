import { useTranslation } from '@sortlist-frontend/translation/ssr';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';

import { HomeJsonLd } from '_components/common/HomeJsonLd';
import { TopLinks } from '_components/common/TopLinks';
import { Companies } from '_components/Companies';
import { AboveTheFoldComponent, BelowTheFoldComponent } from '_components/GrowthbookTestComponent';
import { usePublicAppContext } from '_core/context/public-app-context';
import { PublicApiRepo, useGetLinks, useGetReviews } from '_core/repos/public-api.repo';
import { useTrack } from '_core/repos/track.repo';
import { Header, type ConnectWithTheRightAgencyProps, type CustomersProps } from '_features/home/sections';
import { Catalog } from '_features/home/sections/Catalog';

const ConnectWithTheRightAgency = dynamic<ConnectWithTheRightAgencyProps>(
  () => import('./sections/ConnectWithTheRightAgency').then((mod) => mod.ConnectWithTheRightAgency),
  {
    ssr: true,
  },
);

const AgencyMembers = dynamic<Record<string, unknown>>(
  () => import('./sections/AgencyMembers').then((mod) => mod.AgencyMembers),
  {
    ssr: true,
  },
);

const JoinAsAgency = dynamic<Record<string, unknown>>(
  () => import('./sections/JoinAsAgency').then((mod) => mod.JoinAsAgency),
  {
    ssr: true,
  },
);

const SortlistForYourNeed = dynamic<Record<string, unknown>>(
  () => import('_features/home/sections/SortlistForYourNeed').then((mod) => mod.SortlistForYourNeed),
  {
    ssr: false,
  },
);
const Customers = dynamic<CustomersProps>(
  () => import('_features/home/sections/Customers').then((mod) => mod.Customers),
  {
    ssr: true,
  },
);

export default function HomePage() {
  const { data: reviews } = useGetReviews();
  const { data: links } = useGetLinks(PublicApiRepo.publicApiUrl);
  const { mutateAsync: internalTrack } = useTrack();
  const { domainInfo, locale } = usePublicAppContext();
  const { t } = useTranslation(['home']);
  const router = useRouter();

  useEffect(() => {
    internalTrack({ name: 'homeLoaded', url: location.href }).catch(() => {});
  }, []);

  return (
    <Fragment>
      {reviews && <HomeJsonLd reviews={reviews} />}
      <AboveTheFoldComponent hidden />
      <Header />
      <Companies />
      <Catalog />
      <ConnectWithTheRightAgency reviews={reviews} />
      <BelowTheFoldComponent hidden />
      <AgencyMembers />
      <Customers reviews={reviews} />
      <JoinAsAgency />
      <TopLinks
        seeMore={t('home:sections.skills.seeMore')}
        linksIdentificationKey="home_skills"
        links={links}
        navigationData={{ origin: domainInfo?.getOriginUrl() ?? '', locale, resolvedUrl: router.asPath }}
      />
      <SortlistForYourNeed />
    </Fragment>
  );
}
