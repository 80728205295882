import { ArrowForward } from '@mui/icons-material';
import { Button, Divider } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useRouter } from 'next/router';
import React, { Fragment, ReactElement, useMemo } from 'react';

import { DynamicIcon } from '_components/common/DynamicIcon';
import { SearchTopics } from '_components/SearchTopic/SearchTopics';
import { usePublicAppContext } from '_core/context/public-app-context';
import { PublicApiRepo, useGetLinks } from '_core/repos/public-api.repo';
import { useDomain } from '_core/repos/public-api/domain.repo';
import { IconNames } from '_core/type-utils';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { homeConfig } from '_features/home/home.config';
import { HierarchyType } from '_features/home/types';
import { getHierarchyLinks } from '_features/home/utils';
import { Links } from '_types/public-api';

import * as S from './styles';

const getCrawlerHiddenLinks = (hierarchyLinks: HierarchyType[]): ReactElement => {
  return (
    <Fragment>
      {hierarchyLinks.map((serviceName, keyService) => {
        return (
          <Fragment key={`${serviceName.name}-getCrawlerHiddenLinks-hidden-${keyService}`}>
            {serviceName.links
              ? serviceName.links
                  .sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
                  .map((levelTwo, keyLink) => {
                    const randomKey = Date.now() + Math.random();
                    const localizedLink = getLocalizedUrl(levelTwo);
                    return (
                      <a
                        key={`${randomKey}-${serviceName.name}-${levelTwo.anchor}-hidden-${keyService}-${keyLink}`}
                        href={localizedLink}>
                        {levelTwo.anchor}
                      </a>
                    );
                  })
              : (getCrawlerHiddenLinks(serviceName.children as unknown as HierarchyType[]) as ReactElement)}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export const DesktopCatalog = () => {
  const { data: links } = useGetLinks(PublicApiRepo.publicApiUrl);
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const { domainInfo, locale, getUrl } = usePublicAppContext();
  const { data: domain } = useDomain(domainInfo?.getOriginUrl() ?? '');
  const hierarchyLinks = useMemo(
    () => getHierarchyLinks(links as Links, t('home:sections.catalog.others_v1')),
    [links, t],
  );
  const router = useRouter();

  return (
    <section className="hide show-gt-sm bg-secondary-100 pt-128 pb-64">
      <header className="container-md pt-32">
        <h2 className="h1 pb-16 bold text-center">{t('home:sections.catalog.title_V2')}</h2>
        <h3 className="h6 pb-48 text-secondary-500 text-center">{t('home:sections.catalog.subtitle_V2')}</h3>
      </header>
      <S.CatalogContainer data-testid="catalog" className="layout-row layout-wrap gap-x-16 gap-y-16 container-lg">
        {hierarchyLinks != null &&
          hierarchyLinks.map((category: HierarchyType, index) => (
            <div
              key={`${category.name}-${index}`}
              className="catalog-box rounded-xl bg-neutral-100 shadow-2 p-32 border border-secondary-300 layout-column layout-align-space-between gap-y-16 flex-30">
              <div className="layout-row layout-align-start-center">
                <DynamicIcon
                  className="text-secondary-500 p-8 bg-secondary-100 rounded-lg"
                  iconName={category.iconName as IconNames}
                  width={40}
                  height={40}
                />
                <span className="h5 pl-16 flex bold">{category.translation || category.name}</span>
              </div>
              <div className="layout-column gap-y-16">
                <Divider />
                {category.links?.map((link) => (
                  <S.Anchor key={link.anchor} href={getLocalizedUrl(link)}>
                    <div className="layout-row layout-align-space-between">
                      <span>{link.anchor}</span>
                      <ArrowForward className="p" />
                    </div>
                  </S.Anchor>
                ))}
              </div>
            </div>
          ))}
      </S.CatalogContainer>
      <div className="py-32 layout-row layout-align-center-center">
        <Button
          label={t('home:sections.catalog.browseAllServices')}
          buttonStyle="primary"
          buttonVariant="light"
          size="lg"
          onClick={() => {
            window.location.href = getUrl('client.explore');
          }}
        />
      </div>
      <footer className="container-lg layout-column layout-align-center-center gap-y-16">
        <div className="layout-row layout-align-center-end relative">
          <h6 className="pl-16 bold">{t('home:sections.catalog.searchTitle_V2')}</h6>
        </div>

        <div className="layout-row layout-align-center-center width-50">
          <SearchTopics
            cta="hero"
            page="home"
            element="home"
            className="shadow-5-secondary-500 rounded-xl"
            placeholder={t('common:navbar.menu.whatService')}
            navigationData={{
              origin: domainInfo?.getOriginUrl() ?? '',
              locale,
              resolvedUrl: router.asPath,
              domain: domain,
            }}
            type="service"
          />
        </div>
      </footer>
      <div className="hide">{getCrawlerHiddenLinks(hierarchyLinks)}</div>
    </section>
  );
};
